<template>
  <layout-vertical>
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <div slot="footer">
      <b-card
        title=""
        class=""
      >
      <h2 style="text-align: center">Patrocinadores</h2>
      <div v-for="categoria in patrocinadores" :key="categoria.id">
        <div style="margin-top: 20px; margin-bottom: 20px">
          <h2 v-if="categoria.opcao == 'exibir'" >{{ categoria.title }}</h2>
          <div class="logos-container" style="align-items: center; justify-content: center; display: flex;"> <!-- Adicione a classe "logos-container" -->
            <div v-for="patrocinador in categoria.patrocinadores" :key="patrocinador.id" style="margin-top: 10px;">
              <a :href="patrocinador.link" style="margin-right: 10px; ">
                <img :src="getLogoUrl(patrocinador.logo)" alt="Logo do Patrocinador" :style="{ 'max-width': categoria.size + 'px', 'width': categoria.size + 'px', 'margin': '5px'}" />
              </a>
            </div>
          </div>
        </div>
      </div>
      </b-card>
      <p class="mb-0">
        &copy; {{ client }} {{ new Date().getFullYear() }}
        <span class="float-md-right d-none d-md-block">
          OP3
        </span>
      </p>
    </div>
    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import LayoutVertical from '@core/utils/layouts/layout-vertical-admin/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'
import axios from 'axios'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    BCard,
  },
  data() {
    return {
      client: '',
      footer_type: process.env.VUE_APP_FOOTER_CLIENT,
      patrocinadores: []
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  mounted() {
    this.client = ''
  },
  methods: {
    getLogoUrl(logo) {
      return `${process.env.VUE_APP_API_BASE}/storage/${logo}`
    },
    getListPatrocinadores(){
        axios.get(`${process.env.VUE_APP_API}/patrocinadores`)
          .then(response => {
            this.patrocinadores = response.data.response.list
            console.log(this.patrocinadores)
          })
          .catch(error => {
            console.log(error)
          })
      },
  },
  beforeMount() {
    this.getListPatrocinadores()
  },
}
</script>

<style scoped>
  .singleImage{
    max-height: 132px;
    display: inline-block;
  }
  @media only screen and (max-width: 780px) {
  .singleImage{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 24em;
  }
  .visibilityDesktop{
    display: none;
    }
  }

  @media (min-width: 781px) {
  .visibilityMobile{
     display: none;
    }
  }
  #titleTxt{
    text-align: center;
  }
  @media only screen and (max-width: 390px) {
  .singleImage{
    width: 80em;
    }
  }
  html .content {
    position: relative;
    transition: all .3s ease;
    backface-visibility: hidden;
    min-height: 0px !important;
}
.logos-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* Alinha os elementos verticalmente ao centro */
  /* Se necessário, você pode ajustar outras propriedades flexbox, como:
  justify-content: center; // Para centralizar horizontalmente
  */
}
</style>
